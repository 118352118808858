body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
  background: #190a11;
  background: linear-gradient(134deg, rgba(50, 24, 132, 1) 0%, rgba(0, 0, 0, 1) 100%);
  background-repeat: no-repeat;
  background-size: cover;
  font-size: 14px;
}

option {
  color: black;
}

* {
  font-family: 'Montserrat', sans-serif;
  letter-spacing: normal;
}

::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

.outside__links {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 16px;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  text-decoration: none;
  border-radius: 6px;
}

.outside__links:hover {
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-bg-opacity: .12;
}

.navbar__sicrux {
  background-color: rgb(21,20,25);
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / .1), 0 1px 2px -1px rgb(0 0 0 / .1) !important;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.hamburger__menu:hover {
  background-color: #e6e6e6;
  cursor: pointer;
  border-radius: 20px;
}

.sidebar__custom {
  background: rgb(25, 10, 17);
  background: linear-gradient(180deg, rgba(25, 10, 17, 1) 11%, rgba(25, 10, 17, 1) 65%, rgba(69, 0, 0, 1) 85%);
}

/* .select__custom {
  background-color: #000000;
  border: none;
  border-radius: 15px;
} */

.select__custom:focus {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

select.select__custom option[value="BUSD"] {
  background-image: url(../img/select/busd.png);
}

select.select__custom option[value="USDT"] {
  background-image: url(../img/select/usdt.png);
}

select.select__custom option[value="USDC"] {
  background-image: url(../img/select/usdc.png);
}


.select__custom option {
  background: #190a11 !important;
  color: white;

}

.curency {
  padding: 5px 20px;
}

.curency:hover {
  background: rgb(25, 10, 17);
  background: linear-gradient(270deg, rgba(25, 10, 17, 1) 11%, rgba(25, 10, 17, 1) 25%, #3d0029 75%);
}

.external_link {
  color: #39c0ed;
  display: flex;
  align-items: center;
}

@media (min-width: 701px) {
  .crypto_card {
    width: 50% !important;
  }
}

@media (max-width: 700px) {
  .crypto_card {
    width: 100%;
  }
}